import React from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { Box } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton } from "../../components/Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundtrans} !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  display: flex;
  padding: 2px 24px;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
`;

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="primary" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  position:relative;
  // background: ${({ theme }) => theme.modal.background};
  // box-shadow: ${({ theme }) => theme.shadows.sheryshadow};
  // border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding:30px 20px 40px;
  width: 100%;
  max-height: 100vh;
  border-radius: 31px;
  border: 4px solid rgba(255, 255, 255, 0.01);
  box-shadow:inset 0 48px 79px rgba(255,255,255,0.12);
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.19);
  z-index: ${({ theme }) => theme.zIndices.modal};
  // &:before{
  //   position: absolute;
  //   content: "";
  //   left: 10px;
  //   right: 5px;
  //   bottom: 10px;
  //   top: 10px;
  //   box-shadow: ${({ theme }) => theme.shadows.sheryshadow};
  //   border-radius: 15px;
  //   z-index: -1;
  // }

  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    min-width: ${({ minWidth }) => minWidth};
    max-width: 100%;
  }
`;
