import {serializeTokens} from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()
const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  // {
  //   pid: null,
  //   lpSymbol: 'QSD-REDLC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7b3ae32eE8C532016f3E31C8941D937c59e055B9',
  //   },
  //   token: tokens.qsd,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 0,
    lpSymbol: 'DAWN',
    lpAddresses: {
      8457: '0xc5C6769f8Dc30B2021AD10baf1Deaf459dF9005b',
      2611: '0x59cD0C1879b995197309ECEace9540766d5a2A75',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'DAWN-REDLC LP',
    lpAddresses: {
      8457: '0x3f740e4256b7EF0D3e180242d3dB79b7aC66388f',
      2611: '0x65e75cB33592713DbC852C7345D1886dC173B074',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'USDTR-REDLC LP',
    lpAddresses: {
      8457: '0x820c308178ec38B1b63A8a6F3897c255A9bAC4E7',
      2611: '0x46f013D0AA26d12C39b1c9dFdF67Fcd05a452e52',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'DAWN-USDT LP',
  //   lpAddresses: {
  //     97: '0xfdc4c504ed41c1a9f70be3f81d226fe9880b91a9',
  //     56: '0xfdc4c504ed41c1a9f70be3f81d226fe9880b91a9',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'OSK-USDT LP',
  //   lpAddresses: {
  //     97: '0x80D4F33DB66f3fe32f2eb7FA1A3cCb9734a47FA2',
  //     56: '0x80D4F33DB66f3fe32f2eb7FA1A3cCb9734a47FA2',
  //   },
  //   token: serializedTokens.osk,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddresses: {
  //     97: '0x223a982A1128752Aa9C88C7Ad54Ceec6c918FA5E',
  //     56: '0x223a982A1128752Aa9C88C7Ad54Ceec6c918FA5E',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.busd,
  // },
]

export default priceHelperLps
