import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;

  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === "subMenu" &&
    `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  opacity: ${({ $isActive }) => ($isActive ? "1" : "0.51")};
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.contrast : theme.colors.textSubtle)};
  font-size: 18px;
  font-weight: ${({ $isActive }) => ($isActive ? "300" : "300")};

  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

  ${({ $variant }) =>
    $variant === "default"
      ? `
    padding: 0 16px;
    height: 48px;
  `
      : `
    padding: 4px 4px 0px 4px;
    height: 91px;
    margin:0 30px;

    @media(max-width:575px){margin:0 5px;width:40%;
      svg{max-width:50px;}
    }
  `}
  &::before{
    position: absolute;content: "";left: 0;right: 0;bottom: 0;height: 2px;
    width: 100%;
    background-color: rgba(255,255,255,0.29);z-index: 2;
    display: block;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -ms-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
    opacity: ${({ $isActive }) => ($isActive ? "0" : "0")};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundtrans};opacity:1;
    ${({ $variant }) => $variant === "default" && "border-radius: 16px;"};
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -ms-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out ;
  }
`;

export default StyledMenuItem;
