import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
 
  wbnb: new Token(
    MAINNET,
    '0x00F22F97e249B980A1df5A091FcCbd6599600779',
    18,
    'WREDLC',
    'Wrapped REDLC',
    '',
  ),
  // bnb here points to the wbnb contract. Wherever the currency REDLC is required, conditional checks for the symbol 'REDLC' can be used
  bnb: new Token(MAINNET, '0x00F22F97e249B980A1df5A091FcCbd6599600779', 18, 'REDLC', 'Redlight Coin', ''),

  cake: new Token(
    MAINNET,
    '0x59cD0C1879b995197309ECEace9540766d5a2A75',
    18,
    'DAWN',
    'DAWN Token',
    '',
  ),
  busd: new Token(
    MAINNET,
    '0x73E84bFD35C3f1537A72180D1481E1eABf64B70b',
    18,
    'USDT-R',
    'USDT-R',
    '',
  ),
  syrup: new Token(
    MAINNET,
    '0xA8c120424D289E6574D5ED451798C4528F352Cc8',
    18,
    'DAWNBAR',
    'DawnBar Token',
    '',
  ),
  fusdtr: new Token(
    MAINNET,
    '0xae6aDc43B8522542FE2A95A5B28a8f32c966A497',
    18,
    'FUSDT-R',
    'FUSDT-R',
    '',
  ),
  fredlc: new Token(
    MAINNET,
    '0xBaC227bC0a603cA2Eb6eBC86Cdf8E97856E60315',
    18,
    'FREDLC',
    'FREDLC',
    '',
    
  ),
  btct: new Token(
    MAINNET,
    '0x306De434800cD46eE2573d98065145DeE5F8f03A',
    18,
    'BTCT',
    'BTCT',
    '',
  ),
  etht: new Token(
    MAINNET,
    '0xCC0874bF5525a2147A46C545f26ec46D414A689b',
    18,
    'ETHT',
    'ETHT',
    '',
  ),
  usdtrt: new Token(
    MAINNET,
    '0xC9d296C4E7684b7D4B3D93004CE085fA7349Fc9d',
    18,
    'USDT-RT',
    'USDT-RT',
    '',
  ),
  dawnt: new Token(
    MAINNET,
    '0x0a547405c99e43ee87570A0c5FF8B9029ccB2220',
    18,
    'DAWNT',
    'DAWNT',
    '',
  ),
  redt: new Token(
    MAINNET,
    '0x62bA33bf4a1F751faF23356a38Be80Dd8560B88b',
    18,
    'REDT',
    'REDT',
    '',
  ),
  btctredtlp: new Token(
    MAINNET,
    '0x3179fc129dc00f3decc755fa7cd7607f4da9c629',
    18,
    'BTCT-REDT LP',
    'BTCT-REDT LP',
    '',
  ),
  ethtredtlp: new Token(
    MAINNET,
    '0x428C6E3955A93aF8f5fdF023edfCa3cc40403c13',
    18,
    'ETHT-REDT LP',
    'ETHT-REDT LP',
    '',
  ),
  eth: new Token(
    MAINNET,
    '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    18,
    'ETH',
    'Ethereum',
    '',
  ),
  wbtc: new Token(
    MAINNET,
    '0x765277EebeCA2e31912C9946eAe1021199B39C61',
    8,
    'WBTC',
    'Wrapped BTC',
    '',
  ),
  wbtcredlclp: new Token(
    MAINNET,
    '0xFA1beffffcBE65E5cBa91eEB853Dc91EAcE6E8f0',
    18,
    'WBTC-REDLC LP',
    'WBTC-REDLC LP',
    '',
  ),
  ethredlclp: new Token(
    MAINNET,
    '0x344F961054A63C56654aB52A7F2C4185CBe89EcA',
    18,
    'ETH-REDLC LP',
    'ETH-REDLC LP',
    '',
  ),
  usdtrredlclp: new Token(
    MAINNET,
    '0x46f013D0AA26d12C39b1c9dFdF67Fcd05a452e52',
    18,
    'USDT-R-REDLC LP',
    'USDT-R-REDLC LP',
    '',
  ),
  temp: new Token(
    MAINNET,
    '0x76783f3F5938e6035f5e3F56ad773883c9112128',
    8,
    'temp',
    'temp',
    '',
  ),
  
} as const)

const CAKE_MAINNET = new Token(
  ChainId.MAINNET,
  '0x59cD0C1879b995197309ECEace9540766d5a2A75',
  18,
  'DAWN',
  'Dawn Token',
  '',
)

const CAKE_TESTNET = new Token(
  ChainId.TESTNET,
  '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE = {
  [ChainId.MAINNET]: CAKE_MAINNET,
  [ChainId.TESTNET]: CAKE_TESTNET,
}

export const testnetTokens = defineTokens({
  tto: new Token(
    TESTNET,
    '0xD1C25D7e85F69C188Bf08f39C9B554496ABB3361',
    18,
    'TTO',
    'Test Token',
    '',
  ),
  wbnb: new Token(
    TESTNET,
    '0x4c334fbfc528e993f67b52b96fbb990471f6bd7b',
    18,
    'WREDLC',
    'Wrapped REDLC',
    '',
  ),
  bnb: new Token(
    TESTNET,
    '0x4c334fbfc528e993f67b52b96fbb990471f6bd7b',
    18,
    'REDLC',
    'REDLC',
    '',
  ),
  cake: new Token(TESTNET, '0xF1C251Bf8A67cABb4041725A10aDFC291C38a588', 18, 'DAWN', 'Dawn Token', ''),
  busd: new Token(
    TESTNET,
    '0xa87f184aC46f4e132072d0E0Cfd6a1128D492c2c',
    18,
    'RUSD',
    'RUSD',
    '',
  ),
  
  syrup: new Token(TESTNET, '0x78f6048330F08676180Dea603Ce4430b4a0C94fD', 18, 'DawnBar', 'DawnBar Token', ''),
} as const)

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens