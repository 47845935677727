import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'DAWN',
    lpAddresses: {
      8457: '0xF1C251Bf8A67cABb4041725A10aDFC291C38a588',
      2611: '0x59cD0C1879b995197309ECEace9540766d5a2A75',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'DAWN-REDLC LP',
    lpAddresses: {
      8457: '0x3f740e4256b7EF0D3e180242d3dB79b7aC66388f',
      2611: '0x65e75cB33592713DbC852C7345D1886dC173B074',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
    isShown:true
  },
  {
    pid: 2,
    lpSymbol: 'USDTR-REDLC LP',
    lpAddresses: {
      8457: '0x820c308178ec38B1b63A8a6F3897c255A9bAC4E7',
      2611: '0x46f013D0AA26d12C39b1c9dFdF67Fcd05a452e52',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
    isShown:true
  },
  {
    pid: 5,
    lpSymbol: 'WBTC-REDLC LP',
    lpAddresses: {
      8457: '0x820c308178ec38B1b63A8a6F3897c255A9bAC4E7',
      2611: '0xFA1beffffcBE65E5cBa91eEB853Dc91EAcE6E8f0',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wbnb,
    isShown:false
  },
  {
    pid: 6,
    lpSymbol: 'WBTC-REDLC LP',
    lpAddresses: {
      8457: '0x820c308178ec38B1b63A8a6F3897c255A9bAC4E7',
      2611: '0x344F961054A63C56654aB52A7F2C4185CBe89EcA',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wbnb,
    isShown:false
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'PANGU-USDT LP',
  //   lpAddresses: {
  //     97: '0xfdc4c504ed41c1a9f70be3f81d226fe9880b91a9',
  //     56: '0xfdc4c504ed41c1a9f70be3f81d226fe9880b91a9',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'OSK-USDT LP',
  //   lpAddresses: {
  //     97: '0x80D4F33DB66f3fe32f2eb7FA1A3cCb9734a47FA2',
  //     56: '0x80D4F33DB66f3fe32f2eb7FA1A3cCb9734a47FA2',
  //   },
  //   token: serializedTokens.osk,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddresses: {
  //     97: '0x223a982A1128752Aa9C88C7Ad54Ceec6c918FA5E',
  //     56: '0x223a982A1128752Aa9C88C7Ad54Ceec6c918FA5E',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.busd,
  // },
  // {
  //   pid: 251,
  //   lpSymbol: 'PANGU-REDLC LP',
  //   lpAddresses: {
  //     97: '0x339b4bf2995A1eC8d9B90A144E93845efFCD1EB7',
  //     56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.wbnb,
  // },
  // {
  //   pid: 252,
  //   lpSymbol: 'BUSD-REDLC LP',
  //   lpAddresses: {
  //     97: '0xB998f27a884A0960E4C1cD81a5c155dD3553A95F',
  //     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: serializedTokens.busd,
  //   quoteToken: serializedTokens.wbnb,
  // },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
