export default {
  masterChef: {
    8457: '0x4807a7879a812fE7390dA16cc78f51AADe87b5B3',
    2611: '0x655Ed0918843998F71ADD25273E848b7866d3D95',
  },
  sousChef: {
    8457: '0x3536F5280E4974381Bad6246D31A5bDd1F739E0F',
    2611: '0x1AB63428C24E398c8e52A269Cd8cD0C362B86Ae0',
  },
  lotteryV2: {
    8457: '0x5790c3534F30437641541a0FA04C992799602998',
    2611: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    2611: '0xC460D63CB3CfdDb67839F6cd8531999874326BC4',
    8457: '0xe5b2732e9bD0796afa4B3F412bf6e0426fb4F544',
  },
  pancakeProfile: {
    2611: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    8457: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    2611: '0xDf7952B35f24aCF7fC0487D01c8d261190a60DBa07',
    8457: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    2611: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    8457: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    2611: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    8457: '',
  },
  pointCenterIfo: {
    2611: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    8457: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    2611: '0xFee8A195570a18461146F401d6033f5ab3380849',
    8457: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    2611: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    8457: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionV2: {
    2611: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    8457: '',
  },
  tradingCompetitionMobox: {
    2611: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    8457: '',
  },
  easterNft: {
    2611: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    8457: '0x24ec6962dbe874F6B67B5C508572611261167fA0854F',
  },
  cakeVault: {
    2611: '0x9B07a137A496731625a9aC161ed99C14Ba57e385',
    8457: '0xF35cB352f2AdaFFAFAdA5E34a31a32d65f2b1C49',
  },
  ifoPool: {
    2611: '0x1B2A2f6ed4A1401E8C73B4c2B6172455ce2f78E8',
    8457: '0xc80117F4De3c261163059EbE385d4fCd2Fc8F22E02',
  },
  predictions: {
    2611: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    8457: '',
  },
  chainlinkOracle: {
    2611: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    8457: '',
  },
  bunnySpecialCakeVault: {
    2611: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    8457: '',
  },
  bunnySpecialPrediction: {
    2611: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    8457: '',
  },
  bunnySpecialLottery: {
    2611: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    8457: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    2611: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    8457: '',
  },
  farmAuction: {
    2611: '0xb92Ab7c1edcb273AbA24b062611cEb3681654805D2',
    8457: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    2611: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    8457: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    2611: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    8457: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    2611: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    8457: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    2611: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    8457: '0xfC0c3F11fDA72Cb9A2611F28Ec8D44C0ae4B3ABF86',
  },
  zap: {
    2611: '0xf31CF1Da51cB96527A3AfD365Db5Ce0AFCA35B82',
    8457: '0x987367efbA31311460e2f965408170c46612c71A',
  },
  iCake: {
    2611: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  }
}
