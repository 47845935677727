import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  menuStatus,
  SwapNavIcon,
  LiquideNavIcon,
  FarmNavIcon,
  PoolNavIcon,
  ValidatorPoolNavIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Trade'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        icon: SwapNavIcon,
        label: t('Swap'),
        href: '/swap',
      },
      {
        icon: LiquideNavIcon,
        label: t('Liquidity'),
        href: '/liquidity',
      },
      // {
      //   label: t('Perpetual'),
      //   href: `https://bridge.dawnswap.finance/`,
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        icon: FarmNavIcon,
        label: t('Farms'),
        href: '/farms',
      },
      {
        icon: PoolNavIcon,
        label: t('Vaults'),
        href: '/vaults',
      },
      // {
      //   icon: ValidatorPoolNavIcon,
      //   label: t('Validator Pools'),
      //   href: '/validatorpools',
      // },
    ],
  },
  {
    label: t('Zap'),
    href: '/zap',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      // {
      //   icon: FarmNavIcon,
      //   label: t('Farms'),
      //   href: '/farms',
      // },
      // {
      //   icon: PoolNavIcon,
      //   label: t('Vaults'),
      //   href: '/vaults',
      // },
    ],
  },
  {
    label: t('Bridge'),
    href: 'https://bridge.dawnswap.finance/',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        label: t('USDT <-> USDT-R'),
        href: `https://bridge.dawnswap.finance/`,
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  {
    label: t('Locker'),
    href: 'https://locker.dawnswap.finance/',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        label: t('Dashboard'),
        href: `https://locker.dawnswap.finance/`,
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Lock Tokens'),
        href: `https://locker.dawnswap.finance/token-locker`,
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Lock LP Tokens'),
        href: `https://locker.dawnswap.finance/lptoken`,
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  // {
  //   label: t('Win'),
  //   href: '/prediction',
  //   icon: TrophyIcon,
  //   fillIcon: TrophyFillIcon,
  //   items: [
  //     {
  //       label: t('Trading Competition'),
  //       href: '/competition',
  //       status: menuStatus.LIVE,
  //     },
  //     {
  //       label: t('Prediction (BETA)'),
  //       href: '/prediction',
  //     },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: NftIcon,
  //   fillIcon: NftFillIcon,
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //     {
  //       label: t('Activity'),
  //       href: `${nftsBaseUrl}/activity`,
  //     },
  //   ],
  // },
  // {
  //   label: '',
  //   href: '/info',
  //   icon: MoreIcon,
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Info'),
  //       href: '/info',
  //     },
  //     {
  //       label: t('IFO'),
  //       href: '/ifo',
  //     },
  //     {
  //       label: t('Voting'),
  //       href: '/voting',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/pancakeswap',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://docs.pancakeswap.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // },
]

export default config
