import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import Container from './Container'

const StyledPage = styled(Container)`
  min-height: calc(100vh - 390px);
  padding-top: 16px;position:relative;
  padding-bottom: 16px;
  border-radius: 15px;
  padding: 0px 40px 40px;
  // box-shadow: ${({ theme }) => theme.shadows.sheryshadow};
  &:before{
    position: absolute;
    content: "";
    left: 25px;
    right: 25px;
    bottom: 0px;
    top: -20px;
    box-shadow:inset 0 20px 36px rgba(51,51,51,0.39);
    border-radius:0 0 15px 15px;
    z-index: -1;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.06);
    opacity: 0.7;
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.01);

  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
    
  }
`

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  // const cakePriceUsd = useCakeBusdPrice()
  // const cakePriceUsdDisplay = cakePriceUsd ? `$${cakePriceUsd.toFixed(3)}` : '...'

  const pageMeta = getCustomMeta(pathname, t) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  // let pageTitle = cakePriceUsdDisplay ? [title, cakePriceUsdDisplay].join(' - ') : title
  // if (symbol) {
  //   pageTitle = [symbol, title].join(' - ')
  // }

  return (
    <Head>
      {/* <title>{pageTitle}</title> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string
}

const Page: React.FC<PageProps> = ({ children, symbol, ...props }) => {
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
