import { TokenAmount, Pair, Currency } from '@pancakeswap/sdk'
// import Web3 from 'web3'
import { useMemo } from 'react'
import IPancakePairABI from 'config/abi/IPancakePair.json'
import { Interface } from '@ethersproject/abi'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IPancakePairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )
  // const getPair = (tokenA: string | undefined, tokenB: string | undefined) => {
  //   if (tokenA && tokenB) {
  //     const web3 = new Web3()

  //     const [token0, token1] = tokenA < tokenB ? [tokenA, tokenB] : [tokenB, tokenA]

  //     let abiEncoded1 = web3.eth.abi.encodeParameters(['address', 'address'], [token0, token1])
  //     abiEncoded1 = abiEncoded1.split('0'.repeat(24)).join('')
  //     const salt = web3.utils.soliditySha3(abiEncoded1)
  //     let abiEncoded2 = web3.eth.abi.encodeParameters(
  //       ['address', 'bytes32'],
  //       ['0x9659BBC75a62cD2356eEB287Ee7dbaF7bB082A6b', salt],
  //     )
  //     abiEncoded2 = abiEncoded2.split('0'.repeat(24)).join('').substr(2)
  //     // eslint-disable-next-line prefer-template
  //     const pair =
  //       '0x' +
  //       Web3!
  //         .utils!.soliditySha3(
  //           '0xff' + abiEncoded2,
  //           '0x49c086cc8ec037ad27124e95dba5f92257eb18308813ca3d13a63138c801a6c3',
  //         )!
  //         .substr(26)
  //     return pair
  //   }
  //   return ''
  // }
  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB)
            ? Pair.getAddress(tokenA, tokenB)
            : // getPair(tokenA.address, tokenB.address)
              undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      }),
    [tokens],
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString())),
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
