import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}
    // background: ${({ theme }) => theme.colors.background};
const GlobalStyle = createGlobalStyle`

 
`

export default GlobalStyle
