/* eslint-disable @typescript-eslint/no-unused-vars */
import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import {TwitterIcon, DiscordIcon, MediumIcon }  from "@pancakeswap/uikit/src/components/Svg";
export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  
  {
    label: t(''),
    items: [
      {
        label: t('Swap'),
        href: '/swap',
      },
      {
        label: t('Liquidity (LP tokens)'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: t('Farming'),
        href: '/farms',
      },
      {
        label: t('Vault'),
        href: '/vaults',
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: t('Zap'),
        href: '/zap',
      },
      {
        label: t('Bridge'),
        href: 'https://bridge.dawnswap.finance/',
      },
    ],
  },
  // {
  //   label: t(''),
  //   items: [
  //     {
  //       label: 'redlight.finance',
  //       href: '/',
  //     },
  //     {
  //       label: t('Coingeko'),
  //       href: '/',
  //     },
  //     {
  //       label: t('DEXScreener'),
  //       href: '/',
  //     },
  //     {
  //       label: t('NFTS'),
  //       href: '/',
  //     },
  //     {
  //       label: t('Contact us'),
  //       href: '/',
  //     },
  //   ],
  // },
]
