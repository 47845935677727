import styled, { DefaultTheme } from "styled-components";
import { space, layout, variant } from "styled-system";
import { scaleVariants, styleVariants } from "./theme";
import { BaseButtonProps } from "./types";

interface ThemedButtonProps extends BaseButtonProps {
  theme: DefaultTheme;
}

interface TransientButtonProps extends ThemedButtonProps {
  $isLoading?: boolean;
}

const getDisabledStyles = ({ $isLoading, theme }: TransientButtonProps) => {
  if ($isLoading === true) {
    return `
      &:disabled,
      &.pancake-button--disabled {
        cursor: not-allowed;
      }
    `;
  }

  return `
    &:disabled,
    &.pancake-button--disabled {
      background-color: ${theme.colors.backgroundDisabled};
      border-color: ${theme.colors.backgroundDisabled};
      box-shadow: none;
      color: ${theme.colors.textDisabled};
      cursor: not-allowed;
    }
  `;
};

/**
 * This is to get around an issue where if you use a Link component
 * React will throw a invalid DOM attribute error
 * @see https://github.com/styled-components/styled-components/issues/135
 */

const getOpacity = ({ $isLoading = false }: TransientButtonProps) => {
  return $isLoading ? ".5" : "1";
};

const StyledButton = styled.button<BaseButtonProps>`
  background:transparent !important;
  align-items: center;
  padding: 7px 15px;
  border: 0;
  border-radius: 40px;
  box-shadow: inset 0px 0px 30px rgb(255 255 255 / 30%);
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  height: 44px;
  color: rgba(255, 255, 255, 0.8) !important; 
  line-height: 35px;
  opacity: ${getOpacity};
  outline: 0;
  transition: background-color 0.2s, opacity 0.2s;
  
  svg{fill:white !important;}
  &.shery{margin-top:20px;border-radius:10px;}
  &::before{
    position: absolute;content: "";left: 51%;right: 51%;bottom: 51%;top: 51%;opacity: 0;
     border:none;border-radius: 44px;z-index: -1;@include trans1();
    background: rgb(97,8,8);
    background: -moz-linear-gradient(-45deg,  rgba(97,8,8,1) 0%, rgba(255,175,38,1) 100%); 
    background: -webkit-linear-gradient(-45deg,  rgba(97,8,8,1) 0%,rgba(255,175,38,1) 100%);
    background: linear-gradient(135deg,  rgba(97,8,8,1) 0%,rgba(255,175,38,1) 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#610808', endColorstr='#ffaf26',GradientType=1 );
    
    -webkit-transition:all .5s ease-in-out;
    -moz-transition:all .5s ease-in-out;
    -ms-transition:all .5s ease-in-out;
    -o-transition:all .5s ease-in-out;
    transition:all .5s ease-in-out 
  }
  &:hover:before{
    opacity: 1;left: 0;right: 0;top: 0;bottom: 0;
  }
  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 1;
  }
  &.shery::before{
    left: 0%;right: 0%;bottom: 0%;top: 0%;opacity: 1;border-radius:10px;
    color: $white;font-size: 24px;
    background: rgb(97,8,8); 
    background: -moz-linear-gradient(-45deg,  rgba(97,8,8,1) 0%, rgba(255,175,38,1) 100%); 
    background: -webkit-linear-gradient(-45deg,  rgba(97,8,8,1) 0%,rgba(255,175,38,1) 100%); 
    background: linear-gradient(135deg,  rgba(97,8,8,1) 0%,rgba(255,175,38,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#610808', endColorstr='#ffaf26',GradientType=1 );    
  }
  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: 0.85;
    transform: translateY(1px);
    box-shadow: none;
  }

  ${getDisabledStyles}
  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })}
  ${variant({
    variants: styleVariants,
  })}
  ${layout}
  ${space}
`;

export default StyledButton;
