import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <img width={30}
    style={{marginRight:10}}
    src="/images/tokens/0x59cD0C1879b995197309ECEace9540766d5a2A75.png" alt="" className="src" />
  
  );
};

export default Icon;
