import React from "react";
import styled from "styled-components";
import getExternalLinkProps from "../../util/getExternalLinkProps";
import Text from "../Text/Text";
import { LinkProps } from "./types";

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 25px;
  font-size: 16px;
    opacity: 0.7;
  -webkit-transition:all .5s ease-in-out;
  -moz-transition:all .5s ease-in-out;
  -ms-transition:all .5s ease-in-out;
  -o-transition:all .5s ease-in-out;
  transition:all .5s ease-in-out;
  svg{fill: #fff;}
  &:hover {
    opacity:1;
    text-decoration: none;
    color:#FFAF26;
    text-shadow: 0 0px 10px #fff;
  }
`;

const Link: React.FC<LinkProps> = ({ external, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};
  return <StyledLink as="a" bold {...internalProps} {...props} />;
};

Link.defaultProps = {
  color: "primary",
};

export default Link;
