import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <img 
    {...props}
    width={60}
    style={{marginRight:5}}
    src="/logo.png" alt="" className="src" />
  
  );

};

export default Icon;
